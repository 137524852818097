.codex-editor {
	font-size: 10pt;

	.codex-editor {
		z-index: 100 !important;

		.codex-editor__redactor {
			padding-bottom: 0 !important;
			margin: 0 !important;
		}
	}

	.cdx-list {
		padding: 0 !important;
		padding-left: 1em !important;

		.cdx-list__item {
			line-height: 1em !important;
			padding: 0 !important;
		}
	}

	.ce-paragraph {
		padding: 0 !important;
		line-height: 1em !important;
	}

	hr {
		margin: 8px 0 !important;
		border-color: deeppink;
		cursor: pointer;
	}

	> .codex-editor__redactor {
		margin: 0 !important;
	}
}

.ce-block__content {
	.block-variable {
		font-family: "courier new", "courier", serif;
		font-size: 1px;
		letter-spacing: -1px;
		color: transparent;

		&:before {
			content : "$" attr(data-id);
			color: deeppink;
			letter-spacing: normal;
			font-size: 1rem;
		}
	}

	h1 .block-variable:before {
		font-size: 18pt;
	}
	h2 .block-variable:before {
		font-size: 16pt;
	}
	h3 .block-variable:before {
		font-size: 14pt;
	}

	.block-spacer {
		background: #f3f3f3;
		cursor: pointer;
	}

	.block-columns {
		padding: 0 !important;

		.editor-column {
			&:not(:last-child) {
				border-right: solid 1px #eee;
			}
		}
	}

	.block-tabular {
		width: 100%;
		margin: 5mm 0;
		border: solid 3px deeppink;

		tr {
			&:first-child {
				background: #2980ba;
				color: white
			}

			&:nth-child(even) {
				background: #eee;
			}
		}

		td, th {
			font-size: 8pt;
			padding: 2mm;
		}
	}

	.block-image {
		img {
			max-width: 100%;
		}

		.button-remove {
			outline: 0 !important;
			border: 0;
			background: white;
			padding: 0;
			line-height: 1em;
			font-size: 1.8rem;
			vertical-align: top;
			border-radius: 100px;
			margin: -0.5em 0 0 -0.5em;

			svg {
				margin: 0;
				color: darkred;

				&:hover {
					color: red;
				}
			}
		}
	}

	.file-input {
		background: #eee;
		padding: 2rem;
		color: #555;
		text-align: center;

		&.drop {
			background: #f1f1f1;
		}

		svg {
			font-size: 3rem;
			cursor: pointer;
		}

		p:last-of-type {
			margin: 0;
		}

		input {
			display: none;
		}
	}
}

.ce-settings {
	.block-spacer-settings, .block-columns-settings, .block-image-settings {
		padding: 0.5rem;

		label {
			color: #aaa;
		}

		label, input {
			font-size: 0.8rem !important;
		}
	}

	.block-image-settings {
		padding: 0 0.5rem;
	}

	.button-aspect {
		outline: 0;
		background: #eee;
		border: none;
		margin: 0 auto;
		display: block;
		border-radius: 4px;

		&.active {
			background: #ccc;
		}

		svg {
			margin: 0;
		}
	}

}

.ce-inline-toolbar {
	select.block-variable {
		margin: 0.5rem;
		border: solid 1px #eee;
		padding: 0.3rem 1rem;
		font-size: 1rem !important;
		color : #333 !important;
	}
}

.template-editor {
	position: relative;
	width: 210mm;
	min-height: 297mm;
	padding: 10mm 20mm;
	margin: 0 auto;
	box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
	box-sizing: border-box;
	font-size: 10pt;

	h1 {
		font-size: 18pt;
	}

	h2 {
		font-size: 16pt;
	}

	h3 {
		font-size: 14pt;
	}

	small.pagination {
		position: absolute;
		bottom: 10mm;
		right: 20mm;
	}
}

.page-margin {
	display: grid;
	grid-template-columns: 1fr 3fr;
	align-items: center;


	.input-group {
		margin: 0 !important;

		input, .input-group-text {
			font-size: 0.8rem !important;
		}
	}

	input {

		&::after {
			content : "mm";
		}
	}
}

aside.editor {
	padding: 2rem;
	box-sizing: border-box;
	background: #f3f3f3;

	label {
		color: #aaa;
		font-size: 0.8rem;
		margin: 0;
		line-height: 1.5rem;
	}

	p, .p {
		margin: 1rem 0;
	}
}

.section-title {
	font-size: 0.8rem;
	font-weight: bold;
}
