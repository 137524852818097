.trainee-reports {

	article {
		&.post {
			min-height: 297mm;
			max-width: 210mm;
			width: 100%;
			margin: 0 auto;
			padding: 2cm;
			font-size: 11pt;
			box-shadow: 0 0 10px rgba(0,0,0,0.3), 0 0 50px rgba(0,0,0,0.1);
			color: #333;
			background: #eee;
			overflow-y: auto;

			h2 {
				margin: 0 0 2rem 0;
			}

			h3 {
				margin: 2rem 0 1rem 0;
				font-size: 1.8rem;
			}

			img {
				max-width: 100%;
				height: auto;
			}

			main {
				background: transparent;
				overflow: visible;
				color: #333;
			}
		
			blockquote {
				color: #aaa;
				padding-left: 2rem;
				border-left: solid 3px;
			}
		}
	}

	aside {
		border: solid 1px #444;
		max-height: 80vh;
		overflow-y: auto;
		border-radius: 4px;

		span.a {
			display: block;
			padding: 0.5rem 1rem;
			cursor: pointer;

			&:hover {
				background: rgba(0,0,0,0.05);
			}

			&.active {
				background: rgba(0,0,0,0.1);
			}
		}
	}

	.grid {
		.twenty-eighty {
			grid-template-columns: 2fr 8fr;
		}
	}

	.scale {
		display: flex;
		padding-right: 8px;
		padding-left: 9px;

		> * {
			display: block;
			flex: 1;
			border-left: solid 1px #888;
			height: 0.8rem;
			position: relative;

			&:nth-child(even) {
				height: 0.5rem;
				
				label {
					display: none;
				}
			}

			label {
				position: absolute;
				bottom: -1rem;
				left: 0;
				width: 50px;
				text-align: center;
				transform: translate(-50%, 80%);
				font-size: xx-small;
				font-weight: bold;
			}

			&:last-child {
				border-right: solid 1px #888;
			}
		}
	}
}